/* eslint-disable no-console */
<template>
  <div>
    <v-toolbar
      flat
    >
      <v-btn
        class="mx-4"
        small
        text
        @click="navigateToList"
      >
        <v-icon class="mx-2">mdi-view-headline</v-icon>
        {{ crudLabels.list }}
      </v-btn>

      <v-btn
        class="mx-4"
        color="primary"
        small
        text
        @click="navigateToFormInsert"
      >
        <v-icon  class="mx-2">mdi-playlist-plus</v-icon>
        {{ crudLabels.create }}
      </v-btn>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-container>
          <!-- Editable form: begin -->
          <v-form
            ref="form"
            v-model="isFormContentValid"
            dense>
            <!-- Main Section Fields -->
            <v-row>
              <v-col cols="12" sm="4" md="4" dense >
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
              </v-col>
              <v-col cols="12" sm="4" md="4" dense >
                <v-switch
                  v-model="editedItem[ruleFieldIsActive.name]"
                  :rules="ruleFieldIsActive.rules"
                  :readonly="isFieldReadOnly(ruleFieldIsActive) || ruleAccessModeIsDefault"
                  :disabled="isFieldReadOnly(ruleFieldIsActive) || ruleAccessModeIsDefault"
                  :items="ruleFieldIsActive.references"
                  :label="isActiveLabel"
                  :loading="loading"
                  :type="templateFieldType(ruleFieldIsActive)"
                  dense
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" md="4" dense >

              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4" dense >
                <v-text-field
                  v-model="editedItem[ruleFieldRuleName.name]"
                  :label="ruleFieldRuleName.label"
                  :rules="ruleFieldRuleName.rules"
                  :readonly="isFieldReadOnly(ruleFieldRuleName)"
                  :disabled="isFieldReadOnly(ruleFieldRuleName)"
                  dense
                  :loading="loading"
                  :type="templateFieldType(ruleFieldRuleName)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8" dense >
                <v-text-field
                  v-model="editedItem[ruleFieldDescription.name]"
                  :label="ruleFieldDescription.label"
                  :rules="ruleFieldDescription.rules"
                  :readonly="isFieldReadOnly(ruleFieldDescription)"
                  :disabled="isFieldReadOnly(ruleFieldDescription)"
                  clearable
                  clear-icon="mdi-eraser"
                  dense
                  :loading="loading"
                  :type="templateFieldType(ruleFieldDescription)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4" md="4" dense >
                <v-select
                  v-model="editedItem[ruleFieldRuleAccessMode.name]"
                  :label="ruleFieldRuleAccessMode.label"
                  :rules="ruleFieldRuleAccessMode.rules"
                  :readonly="isFieldReadOnly(ruleFieldRuleAccessMode)"
                  :disabled="isFieldReadOnly(ruleFieldRuleAccessMode)"
                  :items="ruleFieldRuleAccessMode.references"
                  :hint="ruleFieldRuleAccessMode.hint"
                  @change="changeRuleAccessMode"
                  item-text="label"
                  item-value="value"
                  dense
                  :loading="loading"
                  :type="templateFieldType(ruleFieldRuleAccessMode)"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="4" md="4" dense >
                <v-menu v-if="!ruleAccessModeIsDefault"
                  ref="startTsPickerMenu"
                  v-model="startTsPickerMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormatted"
                      @blur="startDate = strDateDDMMYYYYToYYYYMMDD(startDateFormatted)"
                      :label="ruleFieldStartTs.label"
                      append-icon="mdi-calendar"
                      clearable
                      clear-icon="mdi-eraser"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="startTsPickerMenu = false"
                    :max="startDateMax"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4" md="4" dense >
                <v-menu v-if="!ruleAccessModeIsDefault"
                  ref="stopTsPickerMenu"
                  v-model="stopTsPickerMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  dense
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="stopDateFormatted"
                      @blur="stopDate = strDateDDMMYYYYToYYYYMMDD(stopDateFormatted)"
                      :label="ruleFieldStopTs.label"
                      append-icon="mdi-calendar"
                      clearable
                      clear-icon="mdi-eraser"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="stopDate"
                    @input="stopTsPickerMenu = false"
                    :min="stopDateMin"
                    no-title
                    scrollable
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>

            </v-row>

            <v-row  v-if="!ruleAccessModeIsDefault">
              <v-col cols="12" sm="4" md="4" dense >
              </v-col>
              <v-col cols="12" sm="4" md="4" dense >
                <v-text-field
                  v-model="editedItem[ruleFieldRuleActivationStartTime.name]"
                  :label="ruleFieldRuleActivationStartTime.label"
                  :rules="ruleFieldRuleActivationStartTime.rules"
                  :readonly="isFieldReadOnly(ruleFieldRuleActivationStartTime)
                  || ruleAccessModeIsDefault"
                  :disabled="isFieldReadOnly(ruleFieldRuleActivationStartTime)
                  || ruleAccessModeIsDefault"
                  dense
                  :loading="loading"
                  :type="templateFieldType(ruleFieldRuleActivationStartTime)"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" md="4" dense >
                <v-text-field
                  v-model="editedItem[ruleFieldRuleActivationStopTime.name]"
                  :label="ruleFieldRuleActivationStopTime.label"
                  :rules="ruleFieldRuleActivationStopTime.rules"
                  :readonly="isFieldReadOnly(ruleFieldRuleActivationStopTime)
                  || ruleAccessModeIsDefault"
                  :disabled="isFieldReadOnly(ruleFieldRuleActivationStopTime)
                  || ruleAccessModeIsDefault"
                  dense
                  :loading="loading"
                  :type="templateFieldType(ruleFieldRuleActivationStopTime)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row  v-if="!ruleAccessModeIsDefault">
              <v-col cols="12" sm="12" md="12" dense >
                <v-select
                  v-model="editedItem[ruleFieldRuleActivationWeekDays.name]"
                  :label="ruleFieldRuleActivationWeekDays.label"
                  :rules="ruleFieldRuleActivationWeekDays.rules"
                  :readonly="isFieldReadOnly(ruleFieldRuleActivationWeekDays)
                  || ruleAccessModeIsDefault"
                  :disabled="isFieldReadOnly(ruleFieldRuleActivationWeekDays)
                  || ruleAccessModeIsDefault"
                  :items="ruleFieldRuleActivationWeekDays.references"
                  @change="sortSelectedRuleActivationWeekDays"
                  item-text="label"
                  item-value="value"
                  chips
                  deletable-chips
                  multiple
                  dense
                  :loading="loading"
                  :type="templateFieldType(ruleFieldRuleActivationWeekDays)"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" dense >
                <v-card-title class="blue lighten-5 pa-2 ma-0">
                  {{ ruleFieldLocationIdList.label }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" dense >
                <span class="mr-1 text-body-2">{{ getIsAllDoorsSelectedLabel(true) }}</span>
                <v-switch
                  v-model="isAllDoorsSelected"
                  :label="getAllDoorsSelectedLabel"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="8" md="8" dense v-if="!isAllDoorsSelected">
                <v-select
                  v-model="editedItem[ruleFieldLocationIdList.name]"
                  @change="sortSelectedLocationIdList"
                  :rules="ruleFieldLocationIdList.rules"
                  :readonly="isFieldReadOnly(ruleFieldLocationIdList)"
                  :disabled="isFieldReadOnly(ruleFieldLocationIdList)"
                  :items="refDoorListInSelect"
                  item-text="label"
                  item-value="value"
                  multiple
                  chips
                  deletable-chips
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                  :loading="loading"
                  :type="templateFieldType(ruleFieldLocationIdList)"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12" dense >
                <v-card-title class="blue lighten-5 pa-2 ma-0">
                  {{ ruleFieldUserIdList.label }}
                </v-card-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4" md="4" dense >
                <span class="mr-1 text-body-2">{{ getIsAllUsersSelectedLabel(true) }}</span>
                <v-switch
                  v-model="isAllUsersSelected"
                  :label="getAllUsersSelectedLabel"
                  :readonly="isRuleForAll"
                  :disabled="isRuleForAll"
                  :loading="loading"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="8" md="8" dense v-if="!isAllUsersSelected">
                <v-select
                  v-model="editedItem[ruleFieldUserIdList.name]"
                  @change="sortSelectedUserIdList"
                  :rules="ruleFieldUserIdList.rules"
                  :readonly="isFieldReadOnly(ruleFieldUserIdList)"
                  :disabled="isFieldReadOnly(ruleFieldUserIdList)"
                  :items="refUserListInSelect"
                  item-text="label"
                  item-value="value"
                  multiple
                  chips
                  deletable-chips
                  dense
                  clearable
                  clear-icon="mdi-eraser"
                  :loading="loading"
                  :type="templateFieldType(ruleFieldUserIdList)"
                ></v-select>
              </v-col>
            </v-row>

          </v-form>
          <!-- Editable form: end -->
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="!isNewItem && crudAccess.delete"
          color="orange darken-1"
          text
          @click="displayDialogDelete"
        >
          {{ crudLabels.delete }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          {{ $t("appButton.cancel") }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
          :disabled="!isFormContentValid"
        >
          {{ $t("appButton.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="isDisplayedDialogDelete" max-width="800px">
      <v-card>
        <v-card-title class="stong">{{ crudLabels.confirmDelete }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialogDelete"
            >{{ $t("appButton.cancel") }}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDeleteItem"
            >{{ $t("appButton.delete") }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {
  // datatypes,
  // baseDatatypes,
  // reservedDatatypes,
  getBooleanLabel,
  getTemplateFieldType,
  // getTimestampLabel,
  // isoDateStrToTimeStamp,
  strDateYYYYMMDDToDDMMYYYY,
  strDateYYYYMMDDToEndOfDayTimestamp,
  strDateYYYYMMDDToTimestamp,
  strDateDDMMYYYYToYYYYMMDD,
  timestampToYYYYMMDDStrDate,

} from '@/model/datatypes';
import { mapGetters } from 'vuex';
import { genericValues } from '@/model/genericValues';
import { weekDays } from '@/model/weekDays';
import { OPEN, CLOSED, DEFAULT } from '@/model/ruleAccessMode';
import { validateTimeStr } from '@/model/timeString';
import { urlFormatter } from '@/router/index';
import {
  rule,
  ruleFieldIsActive,
  ruleFieldRuleName,
  ruleFieldDescription,
  ruleFieldRuleActivationWeekDays,
  ruleFieldRuleAccessMode,
  ruleFieldRuleActivationStartTime,
  ruleFieldRuleActivationStopTime,
  ruleFieldLocationIdList,
  ruleFieldUserIdList,
  ruleFieldStartTs,
  ruleFieldStopTs,
} from '@/model/rule';

import {
  defaultCrudAccess,
  getAllowedCrudForUser,
  isFieldAllowedForRolesRead,
  isFieldAllowedForRolesWrite,
} from '@/model/accessRolesModel';

import doorsAPI from '@/api/doors.api';
import dbAPI from '../api/rules.api';
import usersAPI from '../api/users.api';
import i18n from '../i18n';

const DEFAULT_RULE_ACTIVATION_START_TIME = '00:00';
const DEFAULT_RULE_ACTIVATION_STOP_TIME = '24:00';

export const DEFAULT_VALUES = {
  editedId: '',
  newItem: {
    ruleName: '',
    description: '',
    isActive: true,
    ruleActivationWeekDays: [],
    ruleAccessMode: '',
    ruleActivationStartTime: DEFAULT_RULE_ACTIVATION_START_TIME,
    ruleActivationStopTime: DEFAULT_RULE_ACTIVATION_STOP_TIME,
    startTs: genericValues.undefinedStartTs,
    stopTs: genericValues.undefinedStopTs,
    locationIdList: [],
    userIdList: [],
  },
  ruleAccessModeIsDefault: false,
};

export default {
  name: 'RuleForm',
  components: {
  },
  data: () => ({
    tablename: 'rule',
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    editedId: DEFAULT_VALUES.editedId,
    editedItem: {},
    isDisplayedDialogDelete: false,
    isFormContentValid: false,
    loading: true,
    model: [],
    refDoorList: [],
    refUserList: [],
    isAllDoorsSelected: false,
    isAllUsersSelected: false,
    isRuleForAll: false,
    runningStatus: '',
    tableHeaders: [],
    startDate: null,
    startDateFormatted: null,
    startTs: genericValues.undefinedStartTs,
    startTsPickerMenu: false,
    stopDate: null,
    stopDateFormatted: null,
    stopTs: genericValues.undefinedStopTs,
    stopTsPickerMenu: false,
    ruleAccessModeIsDefault: DEFAULT_VALUES.ruleAccessModeIsDefault,
  }),

  created() {
    this.crudAccess = getAllowedCrudForUser(rule.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read && !this.crudAccess.create && !this.crudAccess.update) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read_write').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
    this.refreshData();
  },

  watch: {
    $route() {
      this.refreshData();
    },

    isAllDoorsSelected(newValue) {
      this.editedItem.locationIdList = [];
      if (newValue) {
        this.editedItem.locationIdList.push(genericValues.selectAll);
      } else if (this.refDoorList.length === 0) {
        this.getRefDoorList();
      }
    },

    isAllUsersSelected(newValue) {
      this.editedItem.userIdList = [];
      if (newValue) {
        this.editedItem.userIdList.push(genericValues.selectAll);
      } else if (this.refUserList.length === 0) {
        this.getRefUserList();
      }
    },

    startDate(val) {
      this.startDate = val;
      if (!val) {
        this.startDateFormatted = strDateYYYYMMDDToDDMMYYYY(this.startDate);
        this.startTs = 0;
      } else {
        this.startDateFormatted = strDateYYYYMMDDToDDMMYYYY(this.startDate);
        this.startTs = strDateYYYYMMDDToTimestamp(this.startDate);
      }
    },

    startTs(val) {
      this.editedItem.startTs = val;
    },

    stopDate(val) {
      this.stopDate = val;
      if (!val) {
        this.stopDateFormatted = strDateYYYYMMDDToDDMMYYYY(this.stopDate);
        this.stopTs = genericValues.undefinedStopTs;
      } else {
        this.stopDateFormatted = strDateYYYYMMDDToDDMMYYYY(this.stopDate);
        this.stopTs = strDateYYYYMMDDToEndOfDayTimestamp(this.stopDate);
      }
    },

    stopTs(val) {
      this.editedItem.stopTs = val;
    },

  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),

    formTitle() {
      return this.isNewItem ? this.crudLabels.create : this.crudLabels.update;
    },

    isNewItem() {
      return this.editedId === DEFAULT_VALUES.editedId;
    },

    ruleFieldRuleName() { return ruleFieldRuleName; },
    ruleFieldDescription() { return ruleFieldDescription; },
    ruleFieldIsActive() { return ruleFieldIsActive; },
    ruleFieldRuleAccessMode() { return ruleFieldRuleAccessMode; },
    ruleFieldRuleActivationStartTime() { return ruleFieldRuleActivationStartTime; },
    ruleFieldRuleActivationStopTime() { return ruleFieldRuleActivationStopTime; },
    ruleFieldRuleActivationWeekDays() { return ruleFieldRuleActivationWeekDays; },
    ruleFieldLocationIdList() { return ruleFieldLocationIdList; },
    ruleFieldUserIdList() { return ruleFieldUserIdList; },
    ruleFieldStartTs() { return ruleFieldStartTs; },
    ruleFieldStopTs() { return ruleFieldStopTs; },

    getAllDoorsSelectedLabel() {
      return getBooleanLabel(this.isAllDoorsSelected);
    },

    getAllUsersSelectedLabel() {
      return getBooleanLabel(this.isAllUsersSelected);
    },

    isActiveLabel() {
      const name = ruleFieldIsActive.label;
      const value = getBooleanLabel(this.editedItem[ruleFieldIsActive.name]);
      return `${name}: ${value}`;
    },

    refDoorListInSelect() {
      return this.refDoorList.map((rec) => ({
        value: rec.id,
        label: `R${rec.floorNumber} - ${rec.name} (${rec.reference})`,
      })).sort((a, b) => (b.label.toLowerCase() < a.label.toLowerCase()));
    },

    refUserListInSelect() {
      return this.refUserList.map((rec) => ({
        value: rec.id,
        label: `${rec.lastname.toUpperCase()} ${rec.firstname} (${rec.companyName})`,
      })).sort((a, b) => (b.label.toLowerCase() < a.label.toLowerCase()));
    },

    startDateMax() {
      return this.stopDate;
    },

    stopDateMin() {
      return this.startDate;
    },

  },

  methods: {
    confirmDeleteItem() {
      this.deleteRecordThenClose();
    },

    close() {
      this.$router.push(urlFormatter.ruleList);
    },

    closeDialogDelete() {
      this.isDisplayedDialogDelete = false;
    },

    createRecordThenClose() {
      let record = { ...this.editedItem };
      let created = false;
      /* eslint-disable no-param-reassign */
      record = this.validateRecordBeforeWriteAPICall(record);
      /* eslint-enable no-param-reassign */
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.creating') }));
      this.$nextTick(() => {
        dbAPI.create(record)
          .then((data) => {
            created = true;
            if (data.status === 201) {
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.created') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.creation').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
            if (created) this.close();
          });
      });
    },

    deleteRecordThenClose() {
      let deleted = false;
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.deleting') }));
      this.closeDialogDelete();
      this.$nextTick(() => {
        dbAPI.delete(this.editedId)
          .then((data) => {
            deleted = true;
            if (data.status === 204) {
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.deleted') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.deleting').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
            if (deleted) this.close();
          });
      });
    },

    displayDialogDelete() {
      this.isDisplayedDialogDelete = true;
    },

    getData() {
      this.loading = true;
      this.$nextTick(() => {
        this.getRefDoorList();
        this.getRefUserList();
        if (this.editedId) {
          dbAPI.getItem(this.editedId).then((response) => {
            const [record] = response.data.data;
            this.editedItem = record;
            this.startDate = timestampToYYYYMMDDStrDate(record.startTs);
            this.stopDate = timestampToYYYYMMDDStrDate(record.stopTs);
            this.isAllDoorsSelected = (
              this.editedItem.locationIdList.length === 1
              && this.editedItem.locationIdList[0] === genericValues.selectAll
            );
            this.isAllUsersSelected = (
              this.editedItem.userIdList.length === 1
              && this.editedItem.userIdList[0] === genericValues.selectAll
            );
            this.changeRuleAccessMode(this.editedItem.ruleAccessMode);
            this.sortSelectedLocationIdList();
            this.sortSelectedUserIdList();
            this.sortSelectedRuleActivationWeekDays();
          }).catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
              { operation: this.$t('crudLabels.read').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
          });
        } else {
          this.isAllDoorsSelected = (
            this.editedItem.locationIdList.length === 1
            && this.editedItem.locationIdList[0] === genericValues.selectAll
          );
          this.isAllUsersSelected = (
            this.editedItem.userIdList.length === 1
            && this.editedItem.userIdList[0] === genericValues.selectAll
          );
          this.changeRuleAccessMode(this.editedItem.ruleAccessMode);
        }
      });
    },

    getIsAllDoorsSelectedLabel(value) {
      return value ? i18n.tc('rule.isAllDoorsSelectedLabel', 1) : i18n.tc('rule.isAllDoorsSelectedLabel', 0);
    },

    getIsAllUsersSelectedLabel(value) {
      return value ? i18n.tc('rule.isAllUsersSelectedLabel', 1) : i18n.tc('rule.isAllUsersSelectedLabel', 0);
    },

    getRefDoorList() {
      this.loading = true;
      this.$nextTick(() => {
        doorsAPI.getCollectiveDoorList().then((response) => {
          this.refDoorList = response.data.data;
          this.sortSelectedLocationIdList();
        }).catch((error) => {
          const msg = this.$tc(`${'door'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    getRefUserList() {
      this.loading = true;
      this.$nextTick(() => {
        usersAPI.getList().then((response) => {
          this.refUserList = response.data.data;
          this.sortSelectedUserIdList();
        }).catch((error) => {
          const msg = this.$tc(`${'user'}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    isFieldReadOnly(field) {
      return isFieldAllowedForRolesRead(field, this.userRoles)
             && !isFieldAllowedForRolesWrite(field, this.userRoles)
             && !this.isNewItem;
    },

    navigateToFormInsert() {
      if (this.$refs?.form) this.$refs.form.resetValidation();
      this.$router.push(urlFormatter.ruleFormNew);
    },

    navigateToList() {
      this.$refs.form.resetValidation();
      this.$router.push(urlFormatter.ruleList);
    },

    refreshData() {
      try {
        this.crudAccess = getAllowedCrudForUser(rule.crudAllowedRoles, this.userRoles);
        this.editedId = this.$route.params.id ?? DEFAULT_VALUES.editedId;
        this.setCrudLabels();
        this.editedItem = { ...DEFAULT_VALUES.newItem };
        this.getData();
      } catch (error) {
        this.$store.dispatch('displayAlert', error.message);
        throw new Error(this.$t('errMsg.validationError'), error);
      } finally {
        this.loading = false;
      }
    },

    save() {
      if (this.isNewItem) {
        this.createRecordThenClose();
      } else {
        this.updateRecordThenClose();
      }
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
        confirm: i18n.t('appButton.confirm'),
        cancel: i18n.t('appButton.cancel'),
      };
    },

    strDateDDMMYYYYToYYYYMMDD(strDateDMMYYYY) {
      return strDateDDMMYYYYToYYYYMMDD(strDateDMMYYYY);
    },

    changeRuleAccessMode(value) {
      this.ruleAccessModeIsDefault = value === DEFAULT;
      if (this.ruleAccessModeIsDefault) {
        this.editedItem.isActive = true;
        this.editedItem.startTs = 0;
        this.editedItem.stopTs = genericValues.undefinedStopTs;
        this.editedItem.ruleActivationStartTime = DEFAULT_RULE_ACTIVATION_START_TIME;
        this.editedItem.ruleActivationStopTime = DEFAULT_RULE_ACTIVATION_STOP_TIME;
        this.editedItem.ruleActivationWeekDays = [];
        ruleFieldRuleActivationWeekDays.references.forEach((element) => {
          this.editedItem.ruleActivationWeekDays.push(element.value);
        });
      }
      this.setIsRuleForAll(value);
    },

    setIsRuleForAll(ruleAccessModeValue) {
      const isAllInUserList = (this.editedItem.userIdList.length === 1
        && this.editedItem.userIdList[0] === genericValues.selectAll
      );
      this.isRuleForAll = (ruleAccessModeValue === OPEN || ruleAccessModeValue === CLOSED);
      this.isAllUsersSelected = this.isRuleForAll || isAllInUserList;
    },

    sortSelectedLocationIdList() {
      if (this.editedItem.locationIdList.length > 0) {
        this.editedItem.locationIdList.sort((a, b) => {
          const locA = this.refDoorList.find((door) => door.id === a);
          const locB = this.refDoorList.find((door) => door.id === b);
          let labelA = a;
          if (locA) {
            labelA = `R${locA.floorNumber} - ${locA.name} (${locA.reference})`;
          }
          let labelB = b;
          if (locB) {
            labelB = `R${locB.floorNumber} - ${locB.name} (${locB.reference})`;
          }
          return (labelB < labelA);
        });
      }
    },

    sortSelectedUserIdList() {
      if (this.editedItem.userIdList.length > 0) {
        this.editedItem.userIdList.sort((a, b) => {
          const usrA = this.refUserList.find((usr) => usr.id === a);
          const usrB = this.refUserList.find((usr) => usr.id === b);
          let labelA = a;
          if (usrA) {
            labelA = `${usrA.lastname.toUpperCase()} ${usrA.firstname} (${usrA.companyName})`;
          }
          let labelB = b;
          if (usrB) {
            labelB = `${usrB.lastname.toUpperCase()} ${usrB.firstname} (${usrB.companyName})`;
          }
          return (labelB < labelA);
        });
      }
    },

    sortSelectedRuleActivationWeekDays() {
      this.editedItem.ruleActivationWeekDays.sort((a, b) => (weekDays.indexOf(a)
                                                            - weekDays.indexOf(b)));
    },

    templateFieldType(field) {
      return getTemplateFieldType(field.type);
    },

    updateRecordThenClose() {
      let updated = false;
      let editedRecord = { ...this.editedItem };
      const complete = this.validateRecordBeforeWriteAPICall(editedRecord);
      /* eslint-disable no-param-reassign */
      if (complete) editedRecord = complete;
      else return;
      /* eslint-enable no-param-reassign */
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.updating') }));
      this.$nextTick(() => {
        dbAPI.update(editedRecord.id, editedRecord)
          .then((data) => {
            updated = true;
            if (data.status === 201) {
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.updated') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.update').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
            if (updated) this.close();
          });
      });
    },

    validateRecordBeforeWriteAPICall(record) {
      let result = null;
      try {
        /* eslint-disable no-param-reassign */
        // record.isActive
        // record.ruleName
        if (record.description === null) record.description = '';
        // record.ruleActivationWeekDays
        // record.ruleAccessMode
        record.ruleActivationStartTime = validateTimeStr(record.ruleActivationStartTime);
        record.ruleActivationStopTime = validateTimeStr(record.ruleActivationStopTime);
        // record.startTs
        // record.stopTs
        // record.locationIdList
        // record.userIdList
        /* eslint-enable no-param-reassign */
        result = record;
      } catch (error) {
        const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
          { operation: this.$t('crudLabels.creation').toLowerCase() });
        this.$store.dispatch('displayAlert', `${msg}: ${error}.`);
      }
      return result;
    },

  },
};
</script>
