import { DOOR } from '@/model/locationType';
import { itemStatusValues } from '../model/itemStatus';
import axiosInstance from './utils';

export default {
  fullname: 'locations/doors',
  collective: 'collective',
  collectiveFullname: 'locations/doors/collective',
  office: 'office',
  officeFullname: 'locations/doors/office',
  name: 'doors',
  root: 'locations',

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.root}`, clean)
      .catch((error) => {
        const apiErrorMsg = error?.response?.data?.error_msg || '';
        console.log(`${this.name}.api.error: ${error}. ${apiErrorMsg}`);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  delete(itemId) {
    return axiosInstance.delete(`/${this.root}/${encodeURIComponent(itemId)}`)
      .catch((error) => {
        const apiErrorMsg = error?.response?.data?.error_msg || '';
        console.log(`${this.name}.api.error: ${error}. ${apiErrorMsg}`);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  getCollectiveDoorList(getAll = false) { // Not really tested
    let url = `/${this.collectiveFullname}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  getOfficeDoorList(getAll = false) { // Not really tested
    let url = `/${this.officeFullname}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  getList(getAll = false, doorType = '') {
    let url = `/${this.fullname}`;
    if (doorType) url += `/${doorType}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  setDefaultValues(item) {
    const result = { ...item };
    result.locationType = DOOR;
    result.capacity = parseInt(result.capacity, 10);
    result.floorNumber = parseInt(result.floorNumber, 10);
    if (item.name == null) result.name = '';
    if (item.description == null) result.description = '';
    if (item.itemStatus === null) result.itemStatus = itemStatusValues.default;
    return result;
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.id;
    // delete clean.floorNumber;  // Read Only but required by the API Model => DON'T DELETE IT !
    // delete clean.locationType;  // Read Only but required by the API Model => DON'T DELETE IT !
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    return axiosInstance.put(`/${this.root}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
