import axiosInstance from './utils';
import { itemStatusValues } from '../model/itemStatus';

export default {
  name: 'rules',

  create(item) {
    const clean = this.setDefaultValues(item);
    return axiosInstance.post(`/${this.name}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  delete(itemId) {
    return axiosInstance.delete(`/${this.name}/${encodeURIComponent(itemId)}`);
  },

  getItem(itemId) {
    return axiosInstance.get(`/${this.name}/${encodeURIComponent(itemId)}`)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  getList(getAll = false) {
    let url = `/${this.name}`;
    if (getAll === true) url += `?${itemStatusValues.getAllUrlParam}`;
    return axiosInstance.get(url)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },

  setDefaultValues(item) {
    const result = { ...item };
    if (item.isActive === null) result.isActive = true;
    if (item.description == null) result.description = '';
    return result;
  },

  update(itemId, item) {
    const clean = this.setDefaultValues(item);
    delete clean.id;
    delete clean.itemStatus;
    delete clean.creationTs;
    delete clean.lastUpdateTs;
    return axiosInstance.put(`/${this.name}/${encodeURIComponent(itemId)}`, clean)
      .catch((error) => {
        console.log(`${this.name}.api.error`, error);
        console.log('axiosInstance', axiosInstance);
        throw error;
      });
  },
};
