/**
 * Rule schema
 */

import rules from './validationRules';
import i18n from '../i18n';
import {
  datatypes,
  getBooleanTypeReferences,
  getSelectSummaryLabel,
} from './datatypes';
import { getItemStatusTypeReferences } from './itemStatus';
import { getRuleAccessModeLabel, getRuleAccessModeReferences, BADGE } from './ruleAccessMode';
import { getWeekDaysArrayLabel, getWeekDayReferences, weekDays } from './weekDays';
import { roleAdmin, roleSuperAdmin } from './accessRolesModel';

const ruleFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the rule',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const ruleFieldRuleName = {
  name: 'ruleName',
  type: datatypes.string,
  description: 'Name of the rule.',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('rule.fields.ruleName', 1),
  rules: [rules.required],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldDescription = {
  name: 'description',
  type: datatypes.string,
  description: 'Description of the rule.',
  minLength: 0,
  maxLength: 512,
  label: i18n.tc('rule.fields.description', 1),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldRuleAccessMode = {
  name: 'ruleAccessMode',
  type: datatypes.string,
  description: 'Rule access mode',
  minLength: 1,
  maxLength: 128,
  label: i18n.tc('rule.fields.ruleAccessMode', 1),
  hint: i18n.tc('rule.hints.ruleAccessMode', 1),
  references: getRuleAccessModeReferences(),
  default: BADGE,
  valueFormat: {
    method: getRuleAccessModeLabel,
  },
  rules: [rules.isRuleAccessMode],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldRuleActivationStartTime = {
  name: 'ruleActivationStartTime',
  type: datatypes.string,
  description: 'Activation start time',
  minLength: 5,
  maxLength: 5,
  label: i18n.tc('rule.fields.ruleActivationStartTime', 1),
  default: '00:00',
  rules: [rules.required, rules.isTimeString],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldRuleActivationStopTime = {
  name: 'ruleActivationStopTime',
  type: datatypes.string,
  description: 'Activation stop time',
  minLength: 5,
  maxLength: 5,
  label: i18n.tc('rule.fields.ruleActivationStopTime', 1),
  default: '24:00',
  rules: [rules.required, rules.isTimeString],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldRuleActivationWeekDays = {
  name: 'ruleActivationWeekDays',
  type: datatypes.stringArray,
  description: 'Activation week days (string array)',
  label: i18n.tc('rule.fields.ruleActivationWeekDays', 1),
  valueFormat: {
    method: getWeekDaysArrayLabel,
  },
  references: getWeekDayReferences(),
  rules: [rules.requiredItems],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldLocationIdList = {
  name: 'locationIdList',
  type: datatypes.stringArray,
  description: 'Array of locations ID affected by the rule',
  label: i18n.tc('rule.fields.locationIdList', 1),
  rules: [rules.requiredItems],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldUserIdList = {
  name: 'userIdList',
  type: datatypes.stringArray,
  description: 'Array of user ID affected by the rule',
  label: i18n.tc('rule.fields.userIdList', 1),
  valueFormat: {
    method: getSelectSummaryLabel,
  },
  rules: [rules.requiredItems],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldIsActive = {
  name: 'isActive',
  type: datatypes.boolean,
  description: 'The rule is currently active.',
  label: i18n.tc('rule.fields.isActive', 1),
  references: getBooleanTypeReferences(),
  default: true,
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldStartTs = {
  name: 'startTs',
  type: datatypes.timestamp,
  description: 'Validity perdiod start timestamp.',
  label: i18n.tc('rule.fields.startTs', 1),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldStopTs = {
  name: 'stopTs',
  type: datatypes.timestamp,
  description: 'Validity perdiod stop timestamp.',
  label: i18n.tc('rule.fields.stopTs', 1),
  rules: [],
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
};

const ruleFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('rule.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const rule = {
  fields: [
    ruleFieldId,
    ruleFieldRuleName,
    ruleFieldDescription,
    ruleFieldRuleAccessMode,
    ruleFieldRuleActivationStartTime,
    ruleFieldRuleActivationStopTime,
    ruleFieldRuleActivationWeekDays,
    ruleFieldLocationIdList,
    ruleFieldUserIdList,
    ruleFieldIsActive,
    ruleFieldStartTs,
    ruleFieldStopTs,
    ruleFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleAdmin, roleSuperAdmin],
    create: [roleAdmin, roleSuperAdmin],
    update: [roleAdmin, roleSuperAdmin],
    delete: [roleAdmin],
  },
};

export default rule;
export {
  rule,
  ruleFieldId,
  ruleFieldRuleName,
  ruleFieldDescription,
  ruleFieldRuleAccessMode,
  ruleFieldRuleActivationStartTime,
  ruleFieldRuleActivationStopTime,
  ruleFieldRuleActivationWeekDays,
  ruleFieldLocationIdList,
  ruleFieldUserIdList,
  ruleFieldIsActive,
  ruleFieldStartTs,
  ruleFieldStopTs,
  ruleFieldItemStatus,
  weekDays,
};
